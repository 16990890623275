.navbar {
    border-bottom: 1px solid #dddddd;

    .navbar-item img {
        border-radius: 50%;
        margin-right: 5px;
    }

    .navbar-end .icon .xing {
        margin-right: 0;
        max-height: 24px;
        max-width: 24px;
    }
}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px) {
    .navbar-end a {
      display: inline-block;
    }
}